<template>
    <a href="#" @click="openWidget">contact support</a>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    methods: {
        openWidget() {
            if (window.groove !== undefined) window.groove.widget.open()
        },
    },
})
</script>
