<template>
    <b-modal
        id="error-handling-modal"
        v-model="$modals.error_handling"
        hide-footer
        :title="error"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
    >
        <form id="error-handling-form" @submit.prevent="onRefreshPage">
            <div class="d-flex flex-column">
                <div>{{ errorMessage }}</div>
                <div>
                    If this problem continues, please
                    <ContactSupportLink />
                </div>
            </div>
            <div class="d-flex pt-2 justify-content-end">
                <b-button variant="primary" type="submit">OK</b-button>
            </div>
        </form>
    </b-modal>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import ContactSupportLink from './ContactSupportLink.vue'

export default defineComponent({
    name: 'ErrorHandlingModal',
    components: {
        ContactSupportLink,
    },
    props: {
        errorMessage: {
            type: String,
            default: 'You may try refreshing this page to resolve the issue.',
        },
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            error: 'document/apiCallFeedback',
        }),
    },
    methods: {
        onRefreshPage() {
            location.reload()
        },
    },
})
</script>
